<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <br>
    <!-- <div v-for="(order, index) in orders" :key="index"> -->
    <div v-if="!extraMsg" class="order">
      <h3>Order #{{index + 1 }}</h3>
      <br>
      {{order}}
    </div>
    <div v-else class="order">
      {{extraMsg}}
    </div>
    <!-- </div> -->
    <button class="button" :disabled="disableNext" @click="nextOrder">
      {{disableNext ? 'Attente de la prochaine commande ... '+nextCmd+'s' : nextMsg}}
    </button>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      index: 0,
      disableNext: false,
      nextMsg: 'Next Order',
      extraMsg: false,
      size: 0,
      nextCmd: 5,
      order: 'Cocotte Blanche Standard',
      orders: [
                'Cocotte Blanche Standard',
                'Cocotte Blanche Standard',
                'Cocotte Rouge Standard',
                'Cocotte Blanche Standard',
                'Cocotte Rouge Standard',
                'Cocotte Verte Standard',
                'Cocotte Verte Standard',
                'Cocotte Rose Standard',
                'Cocotte Bleu Standard',
                'Cocotte Rouge Standard',
                'Cocotte Blanche Standard',
                'Cocotte Blanche Standard',
                'Cocotte Jaune Standard',
                'Cocotte Jaune Standard',
                'Cocotte Jaune Standard',
                'Cocotte Verte Standard',
                'Cocotte Rouge Standard',
                'Cocotte Blanche Standard',
                'Cocotte Rose Standard',
                'Cocotte Rose Standard',
                'Cocotte Verte Standard',
                'Cocotte Blanche Standard',
                'Cocotte Blanche Standard',
                'Cocotte Rose Standard',
                'Cocotte Rouge Standard',
                'Cocotte Jaune Standard',
              ]
    }
  },
  methods: {
    setProtectedTimeout(callback, timeoutInSec ) {
      if (this._isDestroyed ) {
        return null
      } else {
        return setTimeout(callback, timeoutInSec)
      }
    },
    reset () {
      this.index = 0
      this.extraMsg = false
    },
    nextTick () {
      this.nextCmd -= 1
      if (this.nextCmd <= 0)
      {
        this.disableNext = false
      } else {
        this.setProtectedTimeout(this.nextTick, 1000)
      }
    },
    nextOrder () {
      this.disableNext = true
      this.nextCmd = 5
      this.setProtectedTimeout(this.nextTick, 1000)
      this.index = this.index + 1
      if (this.index > this.size)
      {
        if (this.extraMsg) {
          this.reset()
        } else {
          this.nextMsg = "Back to first command"
          this.extraMsg = "End for today !"
          return
        }
      } else {
        this.extraMsg = false
      }
      this.orders.push(this.order)
      this.order = this.orders.shift()
    }
  },
  mounted() {
    this.size = this.orders.length
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-size: 1em;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.order {  
  font-size: 2em;
  background: #DDDDDD;
  border-radius: 10px;
  margin: 5%;
  margin-right: 20%;
  margin-left: 20%;
  margin-top: 0;
  padding: 5%;

}

button:disabled,
button[disabled]{
  background-color: #80b7c3; /* Green */
  cursor: not-allowed;
}

.order
{
  position: relative;
}
.order:before, .order:after
{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.order:after
{
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}


.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #008CBA;
  border-radius: 10px;
}
.button:hover {
  /*background-color: #798CBA;*/
}
.button:active {
  background-color: #B98CBA;
}
</style>
